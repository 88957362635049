// 鸡思广益
export const newData = [
  {
    id: '180131_1',
    url: '1-1P131132640244.png',
    title: '“1·23充值节”圆满落幕，引爆全民充值“G”时',
    msg: '寒冷的天气并不能阻挡节日火热的气氛，在刚结束的“1·23充值节”，小鸡充值倾力打造的全球首创“充值狂欢趴”取得了非常骄人的成绩。',
    time: '2018-01-31 13:25:11'
  },
  {
    id: '180125_1',
    url: '1-1P125222422G6.png',
    title: '小鸡充值“1·23充值节”，“5G”生活新体验',
    msg: '小鸡充值APP作为第三方移动充值领域的领导者，率先提出“5G”充值理念，并开创性地打造了专属品牌充值日“1·23充值节”来将这一概念落地。',
    time: '2018-01-25 22:23:17'
  },
  {
    id: '180118_1',
    url: '1-1P11H211504L.png',
    title: '草丛三婊&草丛四傻',
    msg: '盘点王者荣耀中蹲草丛的英雄！',
    time: '2018-01-18 09:33:25'
  },
  {
    id: '170926_1',
    url: '1-1F925213611Z9.png',
    title: '《贪吃蛇之塔》丨新游评测',
    msg: '提到贪吃蛇，相信大家都不陌生，很久很久之前，这款游戏可谓是风靡一时。玩法简单，堪称游戏经典款。',
    time: '2017-09-26 08:51:21'
  },
  {
    id: '170915_1',
    url: '1-1F9150T121M1.png',
    title: '英语达人专属游戏《女巫之岛》',
    msg: '又到了周五，今天小鸡充值APP的鸡哥要给你们推荐一款高度自由且剧情丰富的游戏——《女巫之岛》。',
    time: '2017-09-15 08:33:08'
  },
  {
    id: '170914_1',
    url: '1-1F914194T13E.png',
    title: '当小鸡充值APP遇见ofo小黄车',
    msg: '90天免费出行攻略，仅需10秒！',
    time: '2017-09-14 19:47:55'
  },
  {
    id: '170912_1',
    url: '1-1F911225430U5.png',
    title: '《画线魔法师》丨新游测评',
    msg: '小鸡快充APP的鸡哥今天要给大家分享的是不带任何剧情的新游戏——《画线魔法师》。',
    time: '2017-09-12 08:29:45'
  },
  // {
  //   id: '',
  //   url: '',
  //   title: '',
  //   msg: '',
  //   time: ''
  // },
]
// 新闻中心
export const reportData = [
  {
    id: '180125_2',
    url: '1-16121510452J42.png',
    title: '小鸡快充3.0版本正式上线',
    msg: '2016年12月9日，小鸡快充3.0版本正式上线。历经近两个月的用户调研，界面设计、玩法设计、功能测试等多个环节皆有更新，新版本充值更安全、操作更便捷、玩法更新颖！',
    time: '2018-01-25 22:36:44'
  },
  {
    id: '171031_1',
    url: '1-1G03020025H14.png',
    title: '发现福利丨小鸡快充APP',
    msg: '竞技狂欢，“省”者为王！小鸡快充APP万圣节特卖专场，不容错过！',
    time: '2017-10-31 10:42:18'
  },
  {
    id: '171016_1',
    url: '1-1G01614030c06.png',
    title: '一个好消息和一个坏消息',
    msg: '小鸡快充APP的鸡哥有一个好消息和一个坏消息，你想先听哪一个？',
    time: '2017-10-16 14:00:41'
  },
  {
    id: '170817_1',
    url: '1-1FQ622332A96.png',
    title: '小鸡快充APP集龙珠可召唤…',
    msg: '即刻起,在小鸡快充APP上无论你是充值还是邀请好友,甚至什么也不做只是来APP里签个到,我们就送你一颗“龙珠”,可兑换……',
    time: '2017-08-17 08:29:56'
  },
]
// 游戏攻略
export const strategyData = [
  {
    id: '180131_2',
    url: '1-1P130142SK32.png',
    title: '公孙离入门玩法攻略',
    msg: '王者荣耀新版本在游戏画面、英雄等方面做出了不少改动，亮点之一就是上线了一位非常能秀的射手类英雄-公孙离。',
    time: '2018-01-31 13:13:17'
  },
  {
    id: '180130_1',
    url: '1-1P130101035M9.png',
    title: '强控法师张良 实战技巧',
    msg: '王者荣耀中张良的出场率可以说是低到底线了。小鸡快充APP的鸡哥玩了2000多局了，遇到张良的局不超过10场！',
    time: '2018-01-30 10:10:11'
  },
  {
    id: '180126_1',
    url: '1-1P124223350239.png',
    title: '“土匪”刘备',
    msg: '本期，小鸡快充APP的鸡哥和大家分享的是一个比较“奇葩”的英雄——刘备。为什么说刘备是一个奇葩英雄呢？',
    time: '2018-01-26 13:58:11'
  },
  {
    id: '180125_3',
    url: '1-1P124220603623.png',
    title: '女汉子钟无艳',
    msg: '坦克类英雄都是男性，而且非常的结实、威猛。小鸡快充APP的鸡哥今天来介绍个另类的坦克女英雄。',
    time: '2018-01-25 08:49:35'
  },
  {
    id: '180117_1',
    url: '1-1P112130921242.png',
    title: '王者荣耀之夏侯惇',
    msg: '谈到坦克类英雄，玩家们脑子里第一反应是操作简单、无脑冲锋、吸收伤害、打出控制等，鸡哥今天来说个另类的！',
    time: '2018-01-17 21:09:47'
  },
  {
    id: '180112_1',
    url: '1-1P112124629332.png',
    title: '王者荣耀之成吉思汗',
    msg: '鸡哥今天分享的英雄-成吉思汗，比钟馗更不值，鸡哥买这个英雄就是为了增加英雄库的数量，玩过的局更是屈指可数。',
    time: '2018-01-12 12:45:22'
  },
  // {
  //   id: '',
  //   url: '',
  //   title: '',
  //   msg: '',
  //   time: ''
  // },
]

export const newsInfo = [
  {
    id: '180131_1',
    title: '“1·23充值节”圆满落幕，引爆全民充值“G”时',
    time: '2018-01-31 13:25:11',
    html: `<div class="info_text">
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp; &nbsp; &nbsp;
            &nbsp;&ldquo;忽如一夜春风来，千树万树梨花开&rdquo;随着西北冷空气南下，长江流域一带又下起了鹅毛大雪。然而，寒冷的天气并不能阻挡节日火热的气氛，在刚刚结束的&ldquo;<a
              href="http://www.chickpay.cn/news/info?id=180125_1"
              target="_blank"
              ><span style="color: #0000ff">1&middot;23充值节</span></a
            >&rdquo;中，小鸡充值倾力打造的全球首创&ldquo;充值狂欢趴&rdquo;取得了非常骄人的成绩.</span
          ></span
        ><br />
        <br />
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"
              ><img alt="小鸡充值充值节" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708416472659.png" /><br />
              小鸡充值 &nbsp;1.23充值节</span
            ></span
          >
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;在今年2018的首届&ldquo;1&middot;23充值节&rdquo;中，全网交易额就突破10亿大关，参与人数达到了5000余万人次，平均10秒到账的极速充值体验更是为消费者提供了高效便捷的充值新选择。虽然此次&ldquo;充值节&rdquo;作为小鸡充值首创的充值领域盛会，无论是交易额以及覆盖人群都无法和&ldquo;双十一&rdquo;相提并论，但对于移动充值行业来说，这却称的上是一次巨大飞跃。<br />
            &nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;传统充值行业较为混乱，平台多、种类杂，并没有一个权威的平台背书，导致消费者在充值时经常遭遇到不能及时到账以及账号密码被盗等问题，使得用户利益受损，对于网络充值也产生排斥心理。而且传统的充值促销活动虽然覆盖面广，品类全，然而过于繁多的选择也为用户在充值时增加了不必要的干扰，铺天盖地的广告轰炸及促销也使得整个过程变得本末倒置，严重影响用户体验。<br />
            &nbsp;<br />
            &nbsp; &nbsp; &nbsp; &nbsp;作为专注于第三方移动充值的全品类APP，<a href="http://www.chickpay.cn" target="_blank"><span style="color: #0000ff">小鸡充值</span></a
            >此次倾力打造的全球首创&ldquo;1&middot;23充值节&rdquo;，在做到品类完善的同时又做到了很大程度上的克制，专注于消费者充值中频次最高的话费、流量、视频、游戏等领域，并借助时下流行的拼团玩法让此次&ldquo;充值节&rdquo;得以形成指数级扩散，在用户自身得到实惠的同时，也能让亲朋好友享受到了高效便捷的充值体验。独有的智能号箱管理功能及大数据安全体系也让充值过程更省心，可以说&ldquo;1&middot;23充值节&rdquo;让消费者对于未来充值方式有了更加深刻的体验，也成为了一次充值领域的盛大&ldquo;阅兵式&rdquo;。<br />
            &nbsp;<br />
            &nbsp; &nbsp; &nbsp; &nbsp;而对于未来充值行业的发展，小鸡充值也将以领军者的姿态不断昂首迈进，为全国9亿充值需求用户提供更快速、更便捷的服务。</span
          ></span
        ><br />
      </div>`,
  },
  {
    id: '180125_1',
    title: '小鸡充值“1·23充值节”，“5G”生活新体验',
    time: '2018-01-25 22:23:17',
    html: `<div class="info_text"> <span style="font-size:16px;"><span style="font-family:trebuchet ms,helvetica,sans-serif;">&nbsp; &nbsp; &nbsp; &nbsp;<!-- <li><a href="/a/pig">小猪农场</a></li> -->，移动支付成为时代发展新趋势的时候，网络充值的概念也越来越深入人心。面对这一百亿级市场，小鸡充值APP作为第三方移动充值领域的领导者，率先提出&ldquo;5G&rdquo;充值理念，即：更省钱、更安全、更快速、更智能、更简单，并开创性地打造了专属品牌充值日&ldquo;1&middot;23充值节&rdquo;来将这一概念落地。与传统电商充值渠道不同，小鸡充值一站式的充值模式让用户免于眼花缭乱的促销信息轰炸，直接就可享受到最优惠、最便捷的充值体验。而其倾力打造的&ldquo;1&middot;23充值节&rdquo;也将于2018年1月23日正式登场，全球首创的&ldquo;充值双十一&rdquo;将为用户带来&ldquo;5G&rdquo;充值新体验。</span></span><br />
        <br />
        <div style="text-align: center;">
            <span style="font-size:16px;"><span style="font-family:trebuchet ms,helvetica,sans-serif;"><img alt="123充值节" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708407710395.png" /></span></span></div>
        <span style="font-size:16px;"><span style="font-family:trebuchet ms,helvetica,sans-serif;">&nbsp;<br />
        <strong>&nbsp; &nbsp; &nbsp; &nbsp;&ldquo;</strong><strong>1&middot;23充值节</strong><strong>&rdquo;，更省钱</strong><br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;1月23日恰逢&ldquo;双十二&rdquo;之后，过年之前，作为年终充值狂欢盛会，小鸡充值也拿出了满满的诚意，将优惠给到用户，充值折扣让人意想不到，用户可以坐享折上折的超低价格，将省钱进行到底。除此之外，小鸡充值也准备了超多的充值福利：话费奖励、现金红包、优惠券等等应有尽有。享美好生活要会充值，上小鸡充值是真省钱！<br />
        &nbsp;<br />
        <strong>&nbsp; &nbsp; &nbsp; &nbsp;&ldquo;</strong><strong>1&middot;23充值节</strong><strong>&rdquo;，更安全</strong><br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;用户在充值时除了考虑价格因素外，更重要的一点就是充值过程是否安全。目前移动支付行业发展迅猛，但在高速的增长背后也给了许多不法分子可逞之机。钓鱼诈骗、盗取密码等新型经济案件也是屡见不鲜，用户很难保证自己的财产安全。小鸡充值APP采用业界领先的加密安全技术，搭建起了一道值得信赖的大数据安全网络，切实保证用户充值的安全。<br />
        &nbsp;<br />
        <strong>&nbsp; &nbsp; &nbsp; &nbsp;&ldquo;</strong><strong>1&middot;23充值节</strong><strong>&rdquo;，更快速</strong><br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;在传统&ldquo;双十一&rdquo;活动中，造势方式眼花缭乱、促销手段五花八门，消费者承受了过载的信息，很难从海量的信息中找出自己的真正需求。而本次&ldquo;1&middot;23充值节&rdquo;则聚焦于年轻人喜爱的话费、流量、视频、游戏领域，告别铺天盖地的弹窗广告，真正做到无骚扰、无推广，打造快捷充值体验。<br />
        &nbsp;<br />
        <strong>&nbsp; &nbsp; &nbsp; &nbsp;&ldquo;</strong><strong>1&middot;23充值节</strong><strong>&rdquo;，更智能</strong><br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;许多用户可能有多种充值需求，但因为平时工作繁杂无暇顾及，尤其是视频网站会员还有游戏账号等，时间长了不但容易遗忘，许多会员到期了也不知情。小鸡充值独创性地推出了智能号箱管理功能，让用户能更清晰地了解自己账号的情况，充值更省心！<br />
        &nbsp;<br />
        <strong>&nbsp; &nbsp; &nbsp; &nbsp;&ldquo;</strong><strong>1&middot;23充值节</strong><strong>&rdquo;，更简单</strong><br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;简单易操作一直都是充值中最重要的环节，本次&ldquo;1&middot;23充值节&rdquo;，规则和流程也是十分简单，只要输入手机号即可参加，无需任何门槛，配合智能化的提醒功能，让你享受轻松简单的充值体验。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;让我们一起相约<a href="http://www.chickpay.cn" target="_blank"><span style="color:#0000ff;">小鸡充值APP</span></a>，度过一个如梦如幻的充值狂欢周吧！拿上手机，点击屏幕，小鸡充值&ldquo;1&middot;23充值节&rdquo;，让你一充就爱上！</span></span><br />
        </div>`
  },
  {
    id: '180118_1',
    title: '草丛三婊&草丛四傻',
    time: '2018-01-18 09:33:25',
    html: `<div class="info_text">
        <span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><span style="font-size: 16px"
            >&nbsp; &nbsp; &nbsp; &nbsp;在王者荣耀中，草丛是一个非常有战略意义的地方，上中下路、河道以及野区都有。蹲草丛在游戏中非常普遍，本期不说<a
              href="http://www.chickpay.cn/a/jsgy/"
              target="_blank"
              ><span style="color: #0000ff">游戏攻略</span></a
            >，小鸡快充APP的鸡哥想和各位鸡迷们分享王者中蹲草丛的英雄！</span
          ></span
        ><br />
        <br />
        <div style="text-align: center">
          <span style="font-family: trebuchet ms, helvetica, sans-serif"
            ><span style="font-size: 16px"><img alt="小鸡快充APP" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708422369476.png" /></span
          ></span>
        </div>
        <br />
        <span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><span style="font-size: 16px"
            >&nbsp; &nbsp; &nbsp;
            &nbsp;很多小伙伴应该都有过这样的遭遇，尤其是喜欢玩刺客类英雄的玩家，路过草丛的时候被蹲在草丛中的英雄一套给秒了，简直气的都想砸手机。蹲在草丛中能一套秒人的英雄就是传说中的丛林三婊：安琪拉、妲己、王昭君。</span
          ></span
        ><br />
        <br />
        <div style="text-align: center">
          <span style="font-family: trebuchet ms, helvetica, sans-serif"
            ><span style="font-size: 16px"><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708422387595.png" /></span
          ></span>
        </div>
        <br />
        <span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><span style="font-size: 16px"
            >&nbsp; &nbsp; &nbsp;
            &nbsp;小鸡快充APP的鸡哥来说说这三个英雄的一些共同特点。首先，她们都有控制技能，但技能有延时，容易被针对。安琪拉和王昭君的2技能都有一个很明显的释放抬手和延时，遇到稍微有点操作的玩家就很容易被躲过去，妲己的技能虽然肯定能命中，但施法距离太短了，而且飞行的速度很慢。所以她们在草丛中释放2技能有很强的隐蔽性，技能命中的几率要大很多。其次是3技能的威力巨大，基本上控住就能杀死对方，例如安琪拉的炽热光辉、妲己的女王崇拜以及王昭君的凛冬已至，可以说都是大杀器。玩家在使用这三个英雄的时候，如果不会蹲草丛，那么杀伤力至少减半！</span
          ></span
        ><br />
        <br />
        <div style="text-align: center">
          <span style="font-family: trebuchet ms, helvetica, sans-serif"
            ><span style="font-size: 16px"><img alt="王者荣耀英雄" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708422405258.png" /></span
          ></span>
        </div>
        <br />
        <span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><span style="font-size: 16px"
            >&nbsp; &nbsp; &nbsp; &nbsp;聊完非常适合蹲草丛的丛林三婊，下面小鸡快充APP的鸡哥和大家聊得是不要蹲草丛的丛林四傻，他们分别是诸葛亮、东皇太一、芈月和庄周。<br />
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp;诸葛亮是非常厉害的中单法师英雄，尤其是他的被动技能，可以说不会刷被动的<a href="http://www.chickpay.cn/a/yxgl/WZRY-zhugeliang.html" target="_blank"
              ><span style="color: #0000ff">诸葛亮</span></a
            >不是好诸葛亮。但正是由于他的被动导致诸葛不能蹲草丛，一方面他的被动事不能被草丛隐藏的，另一方面是被动会自动攻击范围内的敌人。</span
          ></span
        ><br />
        <br />
        <div style="text-align: center">
          <span style="font-family: trebuchet ms, helvetica, sans-serif"
            ><span style="font-size: 16px"><img alt="诸葛亮玩法" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708422425536.png" /></span
          ></span>
        </div>
        <br />
        <span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><span style="font-size: 16px"
            >&nbsp; &nbsp; &nbsp; &nbsp;<a href="http://www.chickpay.cn/a/yxgl/WZRY-donghuangtaiyi.html" target="_blank"><span style="color: #0000ff">东皇太一</span></a
            >在王者荣耀游戏中很常见，团战中被他的大招控住基本就是等死了，而且也非常的肉。但很少有玩家用他蹲草丛大人，而是采用闪大，原因是他的1技能会让他的周围围绕着三个黑球，而这三个球草丛是掩盖不了的。路过草丛时看到三个球，傻子也知道是东皇在里面。</span
          ></span
        ><br />
        <br />
        <div style="text-align: center">
          <span style="font-family: trebuchet ms, helvetica, sans-serif"
            ><span style="font-size: 16px"><img alt="小鸡快充官网" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708422448750.png" /></span
          ></span>
        </div>
        <br />
        <span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><span style="font-size: 16px"
            >&nbsp; &nbsp; &nbsp; &nbsp;<a href="http://www.chickpay.cn/a/yxgl/wzry-youxigonglve8.html" target="_blank"><span style="color: #0000ff">芈月</span></a
            >也是同样的情况，她在每次释放技能后会触发6只乌鸦围绕在周围。虽然乌鸦比较小，但黑乌乌的还是比价显眼，视力正常的玩家很容易能够发现。</span
          ></span
        ><br />
        <br />
        <div style="text-align: center">
          <span style="font-family: trebuchet ms, helvetica, sans-serif"
            ><span style="font-size: 16px"><img alt="王者丛林英雄" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708422466927.png" /></span
          ></span>
        </div>
        <br />
        <span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><span style="font-size: 16px"
            >&nbsp; &nbsp; &nbsp;
            &nbsp;有玩家会质疑小鸡快充APP的鸡哥：上面的三个英雄不能蹲草丛是因为技能的原因导致有东西围绕在英雄的周围，在草丛中不能被隐藏，但庄周为什么也被列入草丛四傻了呢？确实，<a
              href="http://www.chickpay.cn/a/yxgl/wzry-youxigonglve18.html"
              target="_blank"
              ><span style="color: #0000ff">庄周</span></a
            >周围没有小东西围绕，但他的2技能每隔6秒会有一个浅蓝色的波浪在一定范围了扩散，而这个特效是无法被隐藏的，晓得了不！</span
          ></span
        ><br />
        <br />
        <div style="text-align: center">
          <span style="font-family: trebuchet ms, helvetica, sans-serif"
            ><span style="font-size: 16px"><img alt="小鸡快充APP" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708422486419.png" /></span
          ></span>
        </div>
        <br />
        <span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><span style="font-size: 16px"
            >&nbsp; &nbsp; &nbsp; &nbsp;好啦，关于王者中的丛林三婊和丛林四傻，小鸡快充APP的鸡哥就说到这儿了。希望给大家带去欢乐的同时，也能提供一些帮助，下期见！</span
          ></span
        ><br />
      </div>`
  },
  {
    id: '170926_1',
    title: '《贪吃蛇之塔》丨新游评测',
    time: '2017-09-26 08:51:21',
    html: ` <div class="info_text">
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp; &nbsp; &nbsp;
            &nbsp;提到贪吃蛇，相信大家都不陌生，很久很久之前，这款游戏可谓是风靡一时。玩法简单，堪称游戏经典款。这期，小鸡快充APP的鸡哥要给大家推荐的游戏也是以贪吃蛇为主要元素的一款新游戏&mdash;&mdash;《贪吃蛇之塔》（英文名：Snake
            Towers）。该游戏由Set Snail开发推出，该开发商的其他作品有《illi》、《自行车俱乐部》等。下面，随鸡哥一起来了解下这款新游戏吧！<br />
            &nbsp;</span
          ></span
        >
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="贪吃蛇测评" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423030185.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;开始游戏后，出现画面给玩家第一印象或许依旧是那条通过持续吸收养分来让自己强大的贪吃蛇。但这条蛇这次貌似会住在宇宙最高的塔中，玩家要与它结伴寻找不同的高塔上的宝物，开启一段神秘高塔探索之旅。<br />
            &nbsp;</span
          ></span
        >
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="新游戏测评" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423052312.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;游戏玩法上，与经典贪吃蛇一样，撞墙就挂，及&ldquo;咬舌自尽&rdquo;。玩家在游戏时，会提供200张不同的地图，让你在其中探索，直到死亡。过程中，只要你收集完地图上所有宝石后就会解锁更多的出口，探索之路任重而道远。小鸡快充APP的鸡哥提醒玩家不要忽略了贪吃蛇会越来越大，地图较小，所以越到后来操作难度越大，这就很考验你的技术了。<br />
            &nbsp;</span
          ></span
        >
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="游戏测评" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423069543.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;游戏中的地图出口是能随便进出的，你可以选择一个方向一条道走到黑，也可以随时返回来时出口等。此外，玩家还能在一些地方捡到道具物品，不会受伤害。如果你捡到黄色的&ldquo;魔力球&rdquo;，你便拥有无形的吸力，可将附近的宝石吸进身体里，可叠加使用。<br />
            &nbsp;</span
          ></span
        >
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="贪吃蛇游戏测评" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423087463.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;这款复古像素画风的《贪吃蛇之塔》游戏，无体力值设计，也没有广告弹窗，诚意十足的一款游戏。目前，小鸡快充APP的鸡哥觉得有个不足之处就是不支持简体中文版，如果再能有详细点的<a
              href="http://www.chickpay.cn/news/strategy"
              target="_blank"
              ><span style="color: #0000ff">游戏攻略</span></a
            >会更好。<br />
            &nbsp;</span
          ></span
        >
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="新游戏推荐" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423101559.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp; &nbsp;小鸡快充APP的鸡哥来总结一下今天推荐的《贪吃蛇之塔》游戏：上手简单，画风清晰，对贪吃蛇有特殊情怀的小伙伴非常值得一玩！</span
          ></span
        ><br />
      </div>`
  },
  {
    id: '170915_1',
    title: '英语达人专属游戏《女巫之岛》',
    time: '2017-09-15 08:33:08',
    html: `<div class="info_text">
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp; &nbsp; &nbsp; &nbsp;又到了周五，今天小鸡快充APP的鸡哥要给你们推荐一款高度自由且剧情丰富的游戏&mdash;&mdash;《女巫之岛》。这部像素解谜游戏，不仅剧情烧脑，结局都设置了很多个。<br />
            &nbsp;<br />
            &nbsp; &nbsp; &nbsp; &nbsp;这部《女巫之岛》（英文名：The Witch&#39;s
            Isle）由COCOSOLA研发推出，游戏画风非常独特，故事背景也扣人心弦，更重要的是解谜玩法非常吸引玩家，上线不久后便被苹果官方编辑推荐。但是小鸡快充APP的鸡哥之所以用了&ldquo;英语达人专属游戏&rdquo;这样的描述，就是因为该游戏只有英文版。应该不会有小伙伴为了这部游戏去拼命练习曾经让自己噩梦连连的英语吧，哈哈！<br />
            &nbsp;</span
          ></span
        >
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="女巫之岛" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423414779.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;下面小鸡快充APP的鸡哥来说说关于这部游戏的操作：游戏基本是通过点击完成，游戏中玩家需要跑图，与岛上没睡的人民交流互动，目的是获取有效的线索；另一方面，若屋内的人没有入睡，玩家也可进入与其对话，寻找蛛丝马迹。游戏中，只有在对的时间、对的地点才能获得想要的物品。举个简单的例子：想要借到斧头，充分且必要条件是樵夫回到家里，进行对话才可以。<br />
            &nbsp;</span
          ></span
        >
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="新游推荐" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423396623.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp; &nbsp;再来说说这部作品的背景：在
            &ldquo;女巫之岛&rdquo;的孤岛上，小女主正在睡觉，突然女巫来到窗前告诉她，早上四点之前没有找到女巫丢失的骨灰盒，便会被诅咒死去。于是小女孩在半夜开启了寻找骨灰盒之路，剧情也随之发展，女巫的更多秘密也越来越多的被女孩知晓。<br />
            &nbsp;</span
          ></span
        >
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="小鸡快充官网" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423428715.png" /></span
          ></span>
        </div>
        <br />
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp; &nbsp; &nbsp;
            &nbsp;游戏自由度高，互动性也非常强。比如说钓鱼吧，你觉得好鱼饵就能钓到鱼？错！首先你要有耐心，小鸡快充APP的鸡哥提醒大家要钓到3条以上蓝鱼，至于愿意嘛，后面你就知道啦！有消息说《女巫之岛》有7个不一样的结局，到底是怎样的，建议大家自己挑战一下，看能否一一揭晓。<br />
            &nbsp;</span
          ></span
        >
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="小鸡快充APP" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423444271.png" /></span
          ></span>
        </div>
        <br />
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp; &nbsp; &nbsp;
            &nbsp;如果新手在游戏时，不知道下一步该干嘛时，可以借助于游戏的提示功能，或者点击屏幕左下方的宝箱图标，在钥匙的选项中看那广告找线索。写到这里，小鸡快充APP的鸡哥都觉得烧脑，女巫的秘密究竟是什么，就留给你们去揭晓了。<br />
            &nbsp;<br />
            &nbsp; &nbsp; &nbsp; &nbsp;福利时间：现在起，下载<a href="http://www.chickpay.cn" target="_blank"><span style="color: #0000ff">小鸡快充APP</span></a
            >，输入手机号即刻领取ofo&times;小鸡快充联名月卡，每人可领三张，就是90天内都可以<a href="http://www.chickpay.cn/a/jsgy/ofo-mianfeiqi.html" target="_blank"
              ><span style="color: #0000ff">免费ofo小黄车</span></a
            >骑车了！</span
          ></span
        ><br />
      </div>`
  },
  {
    id: '170914_1',
    title: '当小鸡充值APP遇见ofo小黄车',
    time: '2017-09-14 19:47:55',
    html: `<div class="info_text">
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"
              >下载小鸡快充APP：http://www.chickpay.cn/<br />
              没有任何乱七八糟的条件！<br />
              90天免费出行攻略，只需要10秒即可获取...<br />
              APP上输入手机号即刻领取ofo&times;小鸡快充联名月卡！&nbsp;&nbsp;&nbsp;&nbsp;<br />
              而且最多可以领三张哦！&nbsp;&nbsp;&nbsp;&nbsp;<br />
              也就是你90天内都可以免费骑车了！&nbsp;&nbsp;&nbsp;<br />
              全国通用！&nbsp;&nbsp;&nbsp;<br />
              &nbsp;<br />
              &nbsp;<img alt="" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423568140.png" /><br />
              &nbsp;<br />
              还在等什么？赶紧分享给有需要的小伙伴吧！</span
            ></span
          >
        </div>
      </div>`
  },
  {
    id: '170912_1',
    title: '《画线魔法师》丨新游测评',
    time: '2017-09-12 08:29:45',
    html: `<div class="info_text">
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp; &nbsp; &nbsp; &nbsp;小鸡快充APP的鸡哥今天要给大家分享的是不带任何剧情的新游戏&mdash;&mdash;《画线魔法师》。<br />
            &nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;游戏中，魔法师可以用无数种方法施法，常见的是三消、卡牌等形式。但是这款《画线魔法师》中的&ldquo;神选&rdquo;，通过画线条来施展魔法&mdash;&mdash;&ldquo;灵魂击&rdquo;，这样的特殊玩法，小鸡快充APP的鸡哥也很好奇它的品质到底怎样！一起来往下看！<br />
            &nbsp;</span
          ></span
        ><br />
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"
              ><img alt="新游测评" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423682741.png" /><br />
              新游《画线魔法师》腾讯评分</span
            ></span
          >
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;因《画线魔法师》不带任何剧情，所以玩家是完全没有目标的玩，或者可以天马行空的自我填充游戏目的。游戏场景与三消RPG类似，分战斗区域（上方）与操控区域（下方）。游戏的控方式比较独特，玩家要在非常短的时间里根据屏幕上出现的图案，不喘息不间断的连接所有法阵中的点，方可触发魔法。这可不是&ldquo;手慢无&rdquo;，而是&ldquo;手慢死&rdquo;。<br />
            &nbsp;</span
          ></span
        ><br />
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="画线魔法师攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423700218.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp;
            &nbsp;小鸡快充APP的鸡哥猜测可能是采用复古的像素画风，又或者官方的脑洞太大，游戏上线后就获得了本周新游推荐。但从玩家的反应看，这款游戏的热度会不会只是昙花一现呢？游戏玩法看似简单粗暴，但容错率超低，你确定所有人都能接受这样的0思考考验么？！<br />
            &nbsp;</span
          ></span
        ><br />
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423713794.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp; &nbsp;再来说说介绍的这款新游的战斗模式。<a href="http://www.chickpay.cn/a/yxj/" target="_blank"><span style="color: #0000ff">小鸡快充APP</span></a
            >的鸡哥记得，游戏中炫酷的招式都备受玩家们喜欢。当然了，这也是一款新游戏在宣传时惯用方法，但《画线魔法师》却不走寻常路，游戏里玩家要施展魔法时，没技能，没姿势，0代入感，这是逗我呢吧！<br />
            &nbsp;</span
          ></span
        ><br />
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="画线魔法师游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423728604.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp; &nbsp;除了0思考，始终如一的画线玩法之外，当然少不了内购坑了。小鸡快充APP的鸡哥简单的总结了一下：金币难挣，广告频繁！<br />
            &nbsp;</span
          ></span
        ><br />
        <div style="text-align: center">
          <span style="font-size: 16px"
            ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="小鸡快充新游推荐" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402201708423741135.png" /></span
          ></span>
        </div>
        <span style="font-size: 16px"
          ><span style="font-family: trebuchet ms, helvetica, sans-serif"
            >&nbsp;<br />
            &nbsp; &nbsp; &nbsp; &nbsp;结合鸡哥上面的分析，入不入这款<a href="http://www.chickpay.cn/a/jsgy/youxituijian20.html" target="_blank"><span style="color: #0000ff">像素游戏</span></a
            >的《画线魔法师》的坑，你自己决定喽！游戏充值就上小鸡快充APP，更安全，超便宜！</span
          ></span
        ><br />
      </div>`
  },
  {
    id: '180125_2',
    title: '小鸡快充3.0版本正式上线',
    time: '2018-01-25 22:36:44',
    html: `<div class="info_text">
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif">&nbsp; &nbsp; &nbsp; &nbsp;优惠券可抵现金使用，充值任何商品都有机会获得！这105万的彩蛋到底埋在哪里呢？</span></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="小鸡快充3.2.0版本" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708498657269.jpg" /></span
      ></span>
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        ><strong>&nbsp; &nbsp; &nbsp; &nbsp;活动时间:</strong>2017年3月22日&mdash;2017年3月28日<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; <strong>&nbsp;活动奖励:</strong>随机面值的现金优惠券<br />
        &nbsp;<br />
        <strong>&nbsp; &nbsp; &nbsp; &nbsp;活动对象:</strong>全部用户<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;<strong>活动规则:</strong><br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;1.&nbsp; 活动期间，在&ldquo;小鸡快充&rdquo;内充值任意商品均有机会获取优惠券奖励；<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;2.&nbsp; 本次活动发放的优惠券数量有限，每日上限15万元（7天总计105万），先到先得，发完为止；<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;3.&nbsp; 为维护正常用户的活动权益，活动设置了防作弊机制，恶意充值（小额重复充值）有极大概率无法获得优惠券奖励；<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;4.&nbsp; 如若发现任何作弊行为，官方有权取消该参与者活动资格；<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;5.&nbsp; 有任何问题请联系客服，客服热线：400-088-3232。<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;有关优惠券的秘密，可登录<a href="http://www.chickpay.cn" target="_blank"><span style="color: #0000ff">小鸡快充</span></a
        >APP，在鸡蛋商城抢先兑换体验&hellip;&hellip;</span
      ></span
    ><br />
  </div>`
  },
  {
    id: '171031_1',
    title: '发现福利丨小鸡快充APP',
    time: '2017-10-31 10:42:18',
    html: `<div class="info_text">
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          >竞技狂欢，&ldquo;省&rdquo;者为王！小鸡快充APP万圣节特卖专场，详情如下：<br />
          <br />
          <img alt="steam卡充值" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708498909321.png" /><br />
          <br />
          <img alt="英雄联盟特价充值" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708498924887.png" /><br />
          <br />
          <img alt="CF点券特价充值" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708498938709.png" /><br />
          <br />
          <img alt="守望先锋特价充值" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708498951685.png" /><br />
          <br />
          <img alt="炉石传说特价充值" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708498963726.png" /><br />
          <br />
          <img alt="DOTA2特价充值" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708498976645.png" /><br />
          <br />
          活动规则<br />
          1.活动时间：2017年10月27日至11月3日；<br />
          2.活动期间，参与活动的商品不能同时使用优惠券；<br />
          3.以不正当手段参与活动的用户，小鸡快充APP官方有权取消其参与资格；<br />
          4.若对本次活动有任何疑问，请联系官方客服；<br />
          5.新用户下载小鸡快充APP方法一：各大手机商城里搜索【小鸡快充】即可下载；<br />
          方法二：登录http://www.chickpay.cn小鸡快充官方网站即可下载。</span
        ></span
      >
    </div>
  </div>`
  },
  {
    id: '171016_1',
    title: '一个好消息和一个坏消息',
    time: '2017-10-16 14:00:41',
    html: `<div class="info_text">
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;先来个大消息，文末有&ldquo;大&rdquo;福利哦~<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;小鸡快充APP的鸡哥有一个<span style="color: #ff0000">好消息</span>和一个坏消息，你想先听哪一个？<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;先说坏消息吧：国庆之后，2017的法定假期就已经用光了，所以只能到过年才可以休息了...... &nbsp;&nbsp;&nbsp;<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;那说说好消息：虽然假期是没有了，但下半年的节日可是排的满满的，双十一、圣诞节、元旦等，作为热衷于过节给大家发福利的鸡哥来说，自然不会错过这些机会，所以下半年各位鸡友可以留心我们APP内接下来的各类活动！
        &nbsp;&nbsp;&nbsp;<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;然而这些听上去似乎还很遥远？不用着急，节日我们今天就有！<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;刚过去的10月13日，什么日子？好像没有印象了，其实今天是鸡哥的生日&mdash;&mdash;世界鸡蛋日！<br />
        &nbsp;</span
      ></span
    >
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="小鸡快充APP鸡蛋兑换" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499103461.png" /></span
      ></span>
    </div>
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp;<br />
        <strong><span style="background-color: #ffff00">小知识</span></strong
        ><br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;世界蛋品协会于1999年决定：将每年10月第二个星期五定为&ldquo;世界鸡蛋日&rdquo;。<br />
        &nbsp; &nbsp; &nbsp; &nbsp;为什么呢？<br />
        &nbsp; &nbsp; &nbsp; &nbsp;因为鸡蛋是我们日常饮食中，最普通但也是营养最丰富的食品之一！<br />
        &nbsp; &nbsp; &nbsp; &nbsp;也因为鸡蛋是我们&ldquo;小鸡快充&rdquo;最受欢迎的福利！<br />
        &nbsp;<br />
        <strong><span style="background-color: #ffff00">那鸡蛋在&ldquo;小鸡快充&rdquo;里究竟能干吗呢？</span></strong
        ><br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;简而言之就是在&ldquo;鸡蛋商城&rdquo;里兑换商品啦，1个就可以起兑哦~<br />
        &nbsp; &nbsp; &nbsp; &nbsp;从虚拟的话费、流量、游戏、Q币、视频会员等<br />
        &nbsp; &nbsp; &nbsp; &nbsp;到实物的外星人背包、iPhone手机<br />
        &nbsp; &nbsp; &nbsp; &nbsp;每充值100元就可以领取1个鸡蛋，积累就会有收获！<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;最后，福利大放送，<span style="color: #ff0000"><strong>五十张LOL的5元优惠券</strong></span
        >，数量有限，先到先得哦<br />
        &nbsp;<br />
        <strong><span style="background-color: #ffff00">鸡蛋密语：AAEGQTHXQY</span></strong
        ><br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;下载&ldquo;<a href="http://www.chickpay.cn" target="_blank"><span style="color: #0000ff">小鸡快充</span></a
        >&rdquo;APP最新版后在&ldquo;我的&rdquo;&mdash;&ldquo;兑换码&rdquo;中，凭暗号领取充值优惠券，数量有限，先到先得哦！</span
      ></span
    ><br />
  </div>`
  },
  {
    id: '170817_1',
    title: '小鸡快充APP集龙珠可召唤…',
    time: '2017-08-17 08:29:56',
    html: ` <div class="info_text">
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;&ldquo;俺老孙来也~&rdquo;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;哎，不对，好像走错片场了，不好意思...<br />
        &nbsp;</span
      ></span
    >
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="小鸡快充APP福利" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499221735.png" /></span
      ></span>
    </div>
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;&ldquo;俺老孙来也~&rdquo;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;这回对了......<br />
        &nbsp;</span
      ></span
    >
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="龙珠" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499237755.png" /></span
      ></span>
    </div>
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp;<br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;相信在没有《王者荣耀》出现的那些年，各位&ldquo;鸡友&rdquo;的童年时光都是由包括这两部&ldquo;孙老师&rdquo;领衔主演的&ldquo;大剧&rdquo;在内的诸多影视作品承包的......<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;看完《西游记》，小鸡快充APP的鸡哥对于悟空手里那根无所不能的金箍棒充满了向往；而看完《七龙珠》，鸡哥则对那个可以实现人愿望的神龙充满了好奇......<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;&ldquo;如果给你一个机会，你想许什么愿望？&rdquo;<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;而现在，小鸡快充APP的鸡哥给你一个机会，实现你的愿望...<br />
        &nbsp;</span
      ></span
    >
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="集龙珠活动" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499255009.png" /></span
      ></span>
    </div>
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;无论你是充值还是邀请好友，甚至什么也不做只是来小鸡快充APP里签个到，我们就送你一颗&ldquo;龙珠&rdquo;，是不是比起动画片中过程简单了许多？&nbsp;&nbsp;&nbsp;<br />
        &nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;而集满七颗龙珠，即有机会获得以下好礼<br />
        &nbsp;</span
      ></span
    >
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="小鸡快充礼品兑换" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499275925.png" /></span
      ></span>
    </div>
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp;<br />
        &nbsp; &nbsp; &nbsp; &nbsp;怎么样，神龙的百宝袋是不是非常丰盛呢？快快<a href="http://www.chickpay.cn" target="_blank"><span style="color: #0000ff">下载小鸡快充APP</span></a
        >，来参与我们的活动吧~</span
      ></span
    ><br />
  </div>
`
  },
  {
    id: '180131_2',
    title: '公孙离入门玩法攻略',
    time: '2018-01-31 13:13:17',
    html: `<div class="info_text">
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;王者荣耀S10赛季新版本&ldquo;五军对决&rdquo;在2018年1月29日正式上线了！新版本在游戏画面、英雄等方面都做出了不少的改动，其中新版本也给玩家们提供了一个非常能秀的射手类英雄-公孙离。今天，小鸡快充APP的鸡哥就和大家一起来探讨一下这个英雄。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499410607.png" /><br />
          公孙离 经典</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;被动技能&mdash;&mdash;晚云落<br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;阿离的普攻伤害会为目标叠加枫之印记，每四层印记触发一次爆炸（并叠加新的印记），对周围敌人造成其最大生命百分比的法术伤害并减少所有技能的CD；释放技能后阿离的普通攻击将会额外投掷一枚飞镖并造成物理伤害。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;阿离每次释放技能下次普通攻击可叠加2个印记，通过不断的释放技能快速累积标记即可打出爆炸伤害。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;技能1&mdash;&mdash;岑中归月<br />
        &nbsp; &nbsp; &nbsp; &nbsp;阿离向指定方向瞬步，并将纸伞留在原地（如果纸伞在阿离手中）；纸伞将对范围内目标造成物理伤害及减速效果，持续一段时间。<br />
        &nbsp; &nbsp; &nbsp; &nbsp;被动：阿离能够在释放技能后再次使用技能回归到纸伞的位置；当阿离手中没有纸伞时，自身移动速度与基础攻击速度提升。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;技能2&mdash;&mdash;霜叶舞<br />
        &nbsp; &nbsp; &nbsp; &nbsp;阿离旋转起舞操控纸伞飞旋（如果纸伞在阿离手中），击落飞行物并对范围内敌人造成两段打击，每段都造成法术伤害。<br />
        &nbsp; &nbsp; &nbsp; &nbsp;被动：阿离能够在释放技能后再次使用技能回归到纸伞的位置；当阿离手中没有纸伞时，自身移动速度与基础攻击速度提升。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;技能3&mdash;&mdash;孤鹜断霞<br />
        &nbsp; &nbsp; &nbsp; &nbsp;阿离击退前方的敌人，造成法术伤害，并将纸伞向指定方向掷出（如果纸伞在阿离手中）。<br />
        &nbsp; &nbsp; &nbsp; &nbsp;被动：阿离能够在释放技能后再次使用技能回归到纸伞的位置；当阿离手中没有纸伞时，自身移动速度与基础攻击速度提升。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;推荐装备：急速战靴、末世、无尽战刃、泣血之刃、破甲弓、名刀&middot;司命<br />
        &nbsp; &nbsp; &nbsp; &nbsp;推荐铭文：红色红月，蓝色狩猎、绿色鹰眼</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="公孙离入门攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499445131.png" /><br />
          公孙离 花间舞</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;大家仔细看小鸡快充APP的鸡哥上面对技能的介绍，可以发现一个非常有意思的事情，公孙离的技能全部都有被动效果，这就意味着这个英雄是非常讲究操作细节的。公孙离作为射手类的英雄，她的基础输出小鸡快充APP的鸡哥不敢恭维，前期主要靠被动打伤害。但通过释放技能提升被动移动速度与基础攻击速度，如果出暴击装的话，那么后期的输出还是比较可观的，所以无尽战刃是她的核心装备。由此可见，公孙离还是比较吃发育的，所以前中期打钱是她的第一要务。当然，公孙离的2、3技能的POKE效果也是很不错的，可以在中期配合队友进行推塔和消耗。后期装备成型的公孙离非常的厉害，她和孙尚香一样，特点是输出高，走位灵活，可以游走在战场外围进行输出，而且保命能力很强。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;根据惯例，王者荣耀新出的英雄都是非常IMBA的，但下个版本肯定会进行削弱。所以，<a href="http://www.chickpay.cn/a/jsgy/" target="_blank"
          ><span style="color: #0000ff">小鸡快充APP</span></a
        >的鸡哥建议有金币的玩家赶紧入手公孙离，她肯定是你这个赛季排位上分的利器！</span
      ></span
    ><br />
  </div>`
  },
  {
    id: '180130_1',
    title: '强控法师张良 实战技巧',
    time: '2018-01-30 10:10:11',
    html: `<div class="info_text">
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;俗话说&ldquo;你有张良计，我有过墙梯&rdquo;，历史上张良是一位非常厉害的谋士。当然，他也是汉朝的开国元勋之一，与萧何、韩信被誉为汉出三杰。在王者荣耀中，张良的出场率可以说是低到底线了。小鸡快充APP的鸡哥玩了2000多局了，遇到张良的局不超过10场！但像小鸡快充APP的鸡哥这种喜欢玩刺客的英雄，如果遇到的话，真的很头疼、很无奈！下面鸡哥来和大家好好聊聊。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499934159.png" /></span
      ></span>
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;被动技能&mdash;言灵&middot;咒令<br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;被动：张良对任一敌人造成的相邻两次普攻或技能伤害的时间间隔若小于1.5秒，这两次伤害的间隔时间被视为&ldquo;连续攻击状态&rdquo;，该状态每积累满2秒，会使该敌人额外承受真实伤害，该伤害随英雄等级每级成长。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;技能1&mdash;言灵&middot;壁垒<br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;张良以言灵之术召唤4道并列的法术壁垒，敌人触碰壁垒将受到法术伤害、1秒晕眩和之后0.5秒50%减速，并撞碎这块壁垒。重复触碰壁垒的敌人不再承受晕眩效果，但会承受1.5秒50%减速效果。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;技能2&mdash;言灵&middot;命数<br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;张良以言灵之术召唤侵蚀法阵，法阵每0.5秒对踏入其中的敌人之一造成法术伤害，同时最多存在2个法阵(法阵对小兵野怪额外造成25%伤害)。法阵每13秒存储一次（受到减CD属性影响），最多存储3次，每个法阵持续5.5秒。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="小鸡快充APP" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499955211.png" /></span
      ></span>
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;技能3&mdash;言灵&middot;操纵<br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;张良以言灵之术抓住一名敌方英雄，持续2秒，期间每0.5秒造成法术伤害，并持续支配对方。技能完整释放后，还会在目标身下生成一个法术区域，对踏进其中的敌人造成法术伤害和1秒晕眩。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;推荐装备：冷静之靴+痛苦面具+冰霜法杖+回响之杖+博学者之怒+噬神之书<br />
        &nbsp; &nbsp; &nbsp; &nbsp;推荐铭文：红色圣人+ 蓝色轮回+ 绿色怜悯</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708499972346.png" /></span
      ></span>
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;张良是一个很传统的法师英雄，2技能有点像扁鹊的2技能，他的清兵能力不错，中单和任何英雄对线都不会有太大的问题。发育到4级后建议游走，配合优势路射手进行推塔。这是因为张良单杀能力很弱，但小规模的团战能力非常强，3技能有控有输出，2秒的控制可以配合队友击杀任何一个英雄，这个技能很像<a
          href="http://www.chickpay.cn/a/yxgl/WZRY-donghuangtaiyi.html"
          target="_blank"
          ><span style="color: #0000ff">东皇太一</span></a
        >的3技能，而且1技能的留人效果也很不错。在有张良的局中，张良真的是对方刺客的噩梦，他不放大就根本不能切入。在后期团战中，张良的主要打法就是2技能输出，1技能隔断对手进攻和退守路线，3技能就留给对方切过来的刺客。当然，张良也可以先手留人，直接秒杀对方一个英雄，可以形成以多打少的局面。不过，张良的缺点是技能单CD很长，3技能爆发不足，单杀能力很弱，技能释放后自保能力很差，这就是张良出场率低的原因。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;王者荣耀中，<a href="http://www.chickpay.cn/a/yxgl/wzry-liubang.html" target="_blank"><span style="color: #0000ff">刘邦</span></a
        >、张良、韩信都有了，萧何未来会不会出场，英雄模型、技能如何，很期待的呢。好了，今天<a href="http://www.chickpay.cn" target="_blank"><span style="color: #0000ff">小鸡快充APP</span></a
        >的鸡哥游戏攻略分享时间就结束了。</span
      ></span
    ><br />
  </div>`
  },
  {
    id: '180126_1',
    title: '“土匪”刘备',
    time: '2018-01-26 13:58:11',
    html: ` <div class="info_text">
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;本期的游戏攻略，小鸡快充APP的鸡哥和大家分享的是一个比较&ldquo;奇葩&rdquo;的英雄&mdash;&mdash;刘备。为什么说刘备是一个奇葩英雄呢？首先，刘备明明是射手类英雄，但输出的时候必须和敌方贴身肉搏，这真是让人很无语。其次，在顺风局中刘备可以说是无敌的存在，见神杀神，在逆风局中他就是龟孙子，谁都打不过，冲上去就是送人头，这反差实在让人接受不了。老规矩，技能走起！</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="刘备经典装" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500114556.png" /><br />
          刘备 经典装</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;被动技能-强化霰弹：火铳发射霰弹，每颗弹丸命中敌方英雄会给刘备叠加双抗，持续6秒，可叠加12层。霰弹只有中央2颗弹丸可暴击，目标会受到部分攻击力部分额外弹丸数的总伤害(建筑不受额外弹丸影响)。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;1技能-双重射击：刘备短时间提高移动速度40%，持续1.5秒，并在下次射击时击发两次，每颗弹丸造成一定物理伤害。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;2技能-身先士卒：刘备向前冲锋，若撞击到敌人将造成小范围物理伤害及击退效果，同时自身停止移动。若撞击到敌方英雄将减少该技能2秒冷却时间。<br />
        <br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;3技能-以德服人：刘备清除身上的控制效果，并获得护盾，护盾存在期间刘备免疫控制，并会将普攻和技能造成伤害的50%转化为护盾值(对小兵野怪为20%)，护盾最高值不可超过初始值的150%。开启技能还会获得攻击力加成，持续6秒。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="刘备纽约教父" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500132172.png" /><br />
          刘备 纽约教父</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;推荐装备：追击刀锋、极速战靴、末世、暗影战斧、泣血之刃、破甲弓<br />
        &nbsp; &nbsp; &nbsp; &nbsp;推荐铭文：红色：红月，蓝色：隐匿，绿色：鹰眼<br />
        <br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;小鸡快充APP的鸡哥首先要说明一点，刘备是一个很讲究节奏的英雄，新手玩家不要轻易使用。一方面刘备非常吃资源，他的打野和清兵线能力很强，机动性也不错，基本上自己家的资源都不够用，还要去吃对方野区的经济，所以刘备前中期打钱的节奏一定不能断，否则越来越难打。其次，刘备非常吃装备，因为身板不够肉，输出还要和别人贴身肉搏，这种情况就导致刘备装备一定要领先对手，尤其是核心装三件套：末世、暗影战斧、泣血之刃，越早出来越好打。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="汉昭烈帝" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500147822.png" /><br />
          刘备 汉昭烈帝</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;刘备前期就是发育加绕后配合队友抓人，开局红BUFF对刘备来说非常重要，拿了红就可以配合队友入侵对方野区，杀人、抢野，反正就是抢资源，回来后就是打野、清兵线。中期开团一定不要瞎冲，等肉抗上去后，绕后切对方脆皮。1技能切入，2技能控制，3技能解控，如果发育的好的话，那么就像狼入羊群，为所欲为了。&ldquo;土匪&rdquo;刘备，实至名归。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="万事如意" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500163549.png" /><br />
          刘备 万事如意</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;小鸡快充APP的鸡哥提醒玩家们使用刘备的时，要注意两个技巧：第一，1技能开完后不要立刻上，而是等一下再冲，贴身两枪后1技能又好了，可以再开，那么就等于瞬间打出4枪的效果，这一套下来小脆皮估计就没血了；第二，3技能一定不要乱开，冲过去就开3技能是一个非常容易犯的错误。3技能应该是在生命受到威胁时开启，尤其是被控制的时候。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;更多关于王者荣耀的英雄玩法，请继续关注<a href="http://www.chickpay.cn" target="_blank"><span style="color: #0000ff">小鸡快充APP</span></a
        >官方网站！</span
      ></span
    ><br />
  </div>
`
  },
  {
    id: '180125_3',
    title: '女汉子钟无艳',
    time: '2018-01-25 08:49:35',
    html: ` <div class="info_text">
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;上期的游戏攻略，小鸡快充APP的鸡哥和大家谈的是一个有点另类的坦克类英雄-夏侯惇，他的灵活性应该给大家留下了深刻的印象，今天再和大家分享一个类似的英雄。通常情况下，坦克类英雄都是男性，而且非常的结实、威猛，所以作为同样是坦克类英雄的钟无艳就凸显出来了，话不多说，进入本期的游戏攻略分享之旅！</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500267244.png" /><br />
          钟无艳 经典</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;被动技能-石之炼金：所有伤害会有一半的机率石化敌方英雄，每个英雄在一定时间内只会受到一次石化效果。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="小鸡快充" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500280727.png" /><br />
          钟无艳 生化警戒</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;1技能-狂飙突进：钟无艳朝指定方向突进，下一次普攻变成能够造成一定物理伤害和减速的重击。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;2技能-震慑打击：钟无艳大锤蓄力后击打地面，对范围内敌人造成一定物理伤害，对大锤命中中心的敌方单位造成双倍伤害并减速。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;3技能-飓风之锤：钟无艳旋转大锤，每段打击会对范围内敌人造成物理伤害。被动：钟无艳每段时间内可获得抵挡一定伤害的护盾，护盾永久存在。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="钟无艳" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500297860.png" /><br />
          钟无艳 海滩丽影</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;推荐装备：影忍之足+红莲斗篷+不祥征兆+魔女斗篷+霸者重装+贤者的庇护<br />
        &nbsp; &nbsp; &nbsp; &nbsp;推荐铭文：蓝色调和，绿色虚空，红色宿命</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="钟无艳游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500309168.png" /><br />
          钟无艳 王者之锤</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;钟无艳作为王者荣耀少见的&ldquo;女汉子&rdquo;，她的容错率还是蛮高的，可以说是能抗能打。前期钟无艳主要是和射手走下路，保护射手并打出优势。由于1、2技能有位移和减速，而且3技能的伤害也不俗，所以钟无艳完全在中期可以进行游走，帮助中路和上路打开局面。后期团战，钟无艳的主要作用是打乱对方的阵型，1、2技能切入并减速，3技能群伤很容易将对方坦克和后排分隔开。虽然很可能会被对方射手和法师风筝死，但没有了坦克的对方很难在团战中赢得胜利。<br />
        <br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;最后，小鸡快充APP的鸡哥提醒一下玩家，虽然钟无艳操作难度较低，技能连招也较为单一，只需稍加练习就能轻松上手。但想要玩好她也不是很容易，一定要注意她的被动-石化效果，因为每个英雄在一定时间内只会受到一次石化效果，大家一定要留意。感谢大家的阅读，下期再见！</span
      ></span
    ><br />
  </div>
`
  },
  {
    id: '180117_1',
    url: '1-1P112130921242.png',
    title: '王者荣耀之夏侯惇',
    msg: '谈到坦克类英雄，玩家们脑子里第一反应是操作简单、无脑冲锋、吸收伤害、打出控制等，鸡哥今天来说个另类的！',
    time: '2018-01-17 21:09:47',
    html: ` <div class="info_text">
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;谈到坦克类英雄，玩家们脑子里第一反应是操作简单、无脑冲锋、吸收伤害、打出控制等等。今天，小鸡快充APP的鸡哥和大家分享的英雄夏侯惇就是一个有点另类的坦克类英雄，究竟怎么个另类法，且听鸡哥娓娓道来。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500420698.png" /><br />
          夏侯惇 经典</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;被动技能&mdash;&mdash;噬目：夏侯惇生命低于50%时，每一次技能或普通攻击命中目标都会为其恢复20%的生命，持续8秒(该效果每60秒冷却一次)。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;一技能&mdash;&mdash;豪气斩：夏侯惇挥动大刀，向前发射一道刃风，对刃风命中敌人造成物理伤害并减速，刃风命中英雄后可再次施放豪气斩。击飞前方一定区域的敌人。<br />
        <br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;二技能&mdash;&mdash;龙卷闪：夏侯惇获得一个额外最大生命值10%的护盾，对周围敌人造成法术伤害并减速。夏侯惇8秒内的下三次普通攻击获得额外的真实伤害。每次普攻命中敌人减少1秒不羁之刃的冷却时间。<br />
        <br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;三技能&mdash;&mdash;不羁之刃：夏侯惇扔出链刃将自己拉向既定位置，对范围敌人造成物理伤害并使第一个碰撞到的敌人昏迷，夏侯惇飞抵目标区域后还将追加物理伤害伤害。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500438407.png" /><br />
          夏侯惇 战争骑士</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;推荐装备：红莲斗篷+抵抗之靴+反伤刺甲+振兴之铠+不祥征兆+霸者重装<br />
        &nbsp; &nbsp; &nbsp; &nbsp;推荐铭文：蓝色调和，绿色鹰眼，红色宿命<br />
        <br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;夏侯惇的打法比较灵活，可以上单也可以保护乙方射手，小鸡快充APP的鸡哥的建议是上单因为他的1技能和2技能的清兵能力很不错，而且前期发育对他非常重要。有了一定装备的夏侯惇在中期游走的时候就可以开始发挥了，3技能直接控制加位移贴脸，2技能打出伤害并加上护盾，1技能打出伤害和控制。这一套简直是对方射手和法师的噩梦，还没反应过来就被夏侯惇贴身了，打的话打不过，跑的话也跑不了，真的很无奈。值得提醒的是夏侯惇的被动技能-噬目，这让夏侯惇有了非常IMBA的对抗能力，所以用夏侯惇就不要怂，残血情况下真的可以一个打好几个，而且怎么打都打不死，反而可以杀了对方。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-size: 16px"
        ><span style="font-family: trebuchet ms, helvetica, sans-serif"
          ><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500451501.png" /><br />
          夏侯惇 乘风破浪</span
        ></span
      >
    </div>
    <br />
    <span style="font-size: 16px"
      ><span style="font-family: trebuchet ms, helvetica, sans-serif"
        >&nbsp; &nbsp; &nbsp; &nbsp;从上面可以看出，夏侯惇是一个非常全面的英雄：有控制，有AOE技能，能抗还有位移，而且操作并不难，很容易上手。这个英雄<a
          href="http://www.chickpay.cn"
          target="_blank"
          ><span style="color: #0000ff">小鸡快充APP</span></a
        >的鸡哥强烈推荐给大家，建议大家多练习，这个英雄一定是你排位上分的利器。</span
      ></span
    ><br />
  </div>
`
  },
  {
    id: '180112_1',
    title: '王者荣耀之成吉思汗',
    time: '2018-01-12 12:45:22',
    html: ` <div class="info_text">
    <span style="font-family: trebuchet ms, helvetica, sans-serif"
      ><span style="font-size: 16px"
        >&nbsp; &nbsp; &nbsp; &nbsp;前面，小鸡快充APP的鸡哥和大家分享过最不值英雄之一的<a href="http://www.chickpay.cn/a/yxgl/wzry-zhongkui.html" target="_blank"
          ><span style="color: #0000ff">钟馗</span></a
        >，想必大家还有印象。今天分享的另外一个英雄-成吉思汗，小鸡快充APP的鸡哥感觉他比钟馗更不值，鸡哥买这个英雄就是为了增加英雄库的数量，玩过的局屈指可数。</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-family: trebuchet ms, helvetica, sans-serif"
        ><span style="font-size: 16px"
          ><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500534004.png" /><br />
          成吉思汗 经典</span
        ></span
      >
    </div>
    <br />
    <span style="font-family: trebuchet ms, helvetica, sans-serif"
      ><span style="font-size: 16px"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;玩家们不玩的原因主要有3个，1是前中期太废，无打架能力；2是这个英雄太吃装备了，玩他的话就是默默发育，六神装出来才有的看；3是毫无保命能力，这点和鲁班有的一拼，遇到对面刺客近身就可以丢手机了。最让人气愤的是这个英雄还卖18888，不值之处可见一斑。小鸡快充APP的鸡哥思考了很久还是决定来分享一下成吉思汗的攻略，说不定哪天就有小伙伴需要参考。<br />
        <br />
        &nbsp; &nbsp; &nbsp; 被动技能-追猎：成吉思汗穿越草丛时会短暂提升移动速度，并强化短时内下一次普攻射出两箭，每支箭矢造成物理伤害。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;1技能-鹰眼：成吉思汗释放猎鹰侦查，照亮周围区域并获取其中敌人的视野，持续5秒.可准备2只猎鹰.被动：箭矢第3次伤害同一目标时会额外造物理伤害。<br />
        <br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;2技能-百兽陷阱：投掷最多存在2分钟的陷阱，陷阱被敌人触发后生成减速30%的区域，暴露其中敌人视野，短暂延迟后对区域内造成物理伤害。最多同时放置2个陷阱，未被触发的陷阱落地2秒后隐形。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;3技能-可汗狂猎：成吉思汗骑行中对敌人射出箭矢造成物理伤害，触发普攻法球效果并可以暴击。可储备5次使用次数，并随时间获取使用次数。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;推荐装备：忍者之足+宗师之力+无尽之刃+泣血之刃+破甲弓+破军<br />
        &nbsp; &nbsp; &nbsp; &nbsp;推荐铭文：蓝色狩猎+绿色鹰眼+红色无双</span
      ></span
    ><br />
    <br />
    <div style="text-align: center">
      <span style="font-family: trebuchet ms, helvetica, sans-serif"
        ><span style="font-size: 16px"
          ><img alt="游戏攻略" src="https://res.51tiancang.com//cc-tc/m/resources/21011/202402211708500551774.png" /><br />
          成吉思汗 维京掠夺者</span
        ></span
      >
    </div>
    <br />
    <span style="font-family: trebuchet ms, helvetica, sans-serif"
      ><span style="font-size: 16px"
        >&nbsp; &nbsp; &nbsp;
        &nbsp;成吉思汗由于拥有被动技能-追猎，那么可以选择下路主力发育或者打野，打野的话带一把打野刀就可以了。作为没啥控制和爆发技能的射手英雄，小鸡快充APP的鸡哥建议前中期就不要参团，意义不大。最好是多收线，但不要离塔太远，没有近的兵线的话可以进野区打野，一定要忍到几个大件出来再考虑参团。参团的时候要注意在附近草丛投掷2技能，以防对方刺客从草丛里杀出来。追击和逃跑也要注意利用被动，尽量通过草丛，可以加快移速。<br />
        <br />
        &nbsp; &nbsp; &nbsp;
        &nbsp;当然成吉思汗不是那么一无是处，还是有几个值得称道的敌方。首先他的射程非常远，远程消耗还是很不错；其次2技能可以开视野，这个技能和百里的陷阱很像，很有战略意义；最后拥有装备后的他开启大招后非常厉害，5箭可以在短时间内打出很高的伤害。<br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;总体而言，成吉思汗是一个容错率很低的英雄，用这个英雄很冒险，发育起来就是别人的爸爸，发育不起来就得叫别人爸爸。游戏充值就用<a
          href="http://www.chickpay.cn"
          target="_blank"
          ><span style="color: #0000ff">小鸡快充APP</span></a
        >，超便宜！</span
      ></span
    ><br />
  </div>
`
  }
]